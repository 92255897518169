import React from 'react';
import { withPrefix } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';

const HeroContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Overlay = styled.div`
  width: 100%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

function BlogIndexPage() {
  const overlay = (
    <Overlay>
      <div className="columns image-overlay is-vcentered">
        <div className="column is-1"></div>
        <div className={'column is-10'}>
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-family-secondary has-text-left has-text-white"
            style={{
              lineHeight: '1',
            }}
          >
            Latest Stories
          </h1>
        </div>
      </div>
    </Overlay>
  );
  return (
    <>
      <Layout customSEO>
        <HeroContainer>
          <div
            className="full-width-image-container margin-top-0"
            style={{
              backgroundImage: `url('/img/blog-index.jpg')`,
            }}
          >
            {overlay}
          </div>
        </HeroContainer>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default BlogIndexPage;
